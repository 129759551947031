import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function GuestGuard({ children }) {
    const { isLoggedIn } = useSelector((state) => state.auth);

    /*
    if (isLoggedIn) {
        return <Navigate to="/products" />;
    }
    */

    return children;
}

GuestGuard.propTypes = {
    children: PropTypes.any
};

export default GuestGuard;
