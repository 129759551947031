/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Routes as Switch,
  Navigate,
  Route
} from 'react-router-dom';

import GuestGuard from './components/GuestGuard';
import AuthGuard from './components/AuthGuard';
import DefaultLayout from './layouts/Defaul';


const routesConfig = [

  /*
  {
    exact: true,
    path: "/",
    component: () => <Navigate to="/login" />
  },
  */
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("./views/auth/LoginView"))
  },
  {
    path: '/*',
    guard: AuthGuard,
    //layout: DefaultLayout,
    routes: [
      /*
        {
          exact: true,
          path: '/app',
          component: () => <Redirect to="/app/reports/dashboard" />
        },
        {
          exact: true,
          guard: AuthGuard,
          path: '/home',
          component: lazy(() => import('./views/home/HomeView'))
        },
      */
      {
        exact: true,
        //guard: AuthGuard,
        path: '/*',
        component: lazy(() => import('./views/pedidos/PedidosView'))
      },
      {
        component: () => <Navigate to="/404" />
      }
    ]
  },
  {
    path: '*',
    routes: [
      {
        component: () => <Navigate to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<div className='fixed z-50 left-0 top-0 bottom-0 right-0 flex justify-center items-center bg-white bg-opacity-25'>
    <img className='w-56 h-56 animate-pulse' src='https://d18i3wxus81zw3.cloudfront.net/fit-in/512x512/valle-norte.png' />
  </div>}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        //const profiels = route.profiels || null;
        //const nprofiels = route.nprofiels || null;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            element={<Guard>
              <Layout>
                {route.routes
                  ? renderRoutes(route.routes)
                  : <Component />}
              </Layout>
            </Guard>
            }
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
