import api from "./api";
import TokenService from "./token.service";
import SesionJwt from './jwt.json'

class AuthService {
  login(username, password) {

    return api
      .post("/auth/signin", {
        email: username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
        }

        return response.data;
      });
  }

  me(sessionId) {

    /* 
    const user = {
      "statusCode": 1,
      "message": "Ok",
      "refreshToken": "fef15084-caf1-43d6-a89a-4a0d3e2e34f0",
      "accessToken": "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJpc3MiOiJodHRwczovL2FwaXRhbWJvcy5ncnVwb3ZhbGxlbm9ydGUuY29tLnBlOjcwMzAvIiwiaWF0IjoxNjU3OTgyNjM5LCJleHAiOjE2NTc5ODI5MzksInN1YiI6IjE1NTg1NFNFNDVGRThUNTU4RVk1VDVFRCJ9.dy1X2Gyuas_r21KtxU9XSq2wr3VjrIKCNcAkiwuHGmhvfitQwkxEwjdDaBpUpmB5At-9wOb2OrWEPTsYXkqb4w",
      "tokenType": "Bearer"
    }

    TokenService.setUser(user);

    return user
    */

    return api
      .post("/APIColgate/api/auth/login-customer", { sessionId: sessionId })
      .then(response => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
        }
        return response.data;
      })
      .catch(e => {
        console.log("Error Login")
        console.log(e)

        //Comentar para produccion
        /*
                if (sessionId) {
                  TokenService.setUser(SesionJwt);
                  return SesionJwt;
                }
        */

        return null
      });
  }

  logout() {
    return api
      .post("/auth/logout")
      .then(response => {
        if (response.data.accessToken) {
          TokenService.removeUser();
        }
        return response.data;
      });
  }


  /*
  logout() {
    TokenService.removeUser();
  }
  */

  register(username, email, password) {
    return api.post("/auth/signup", {
      username,
      email,
      password
    });
  }
}

export default new AuthService();
