export const types = {

    login: '[Auth] Login',
    logout: '[Auth] Logout',

    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',

    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',

    openCreateProductModal: '[UI] Open Create Product Modal',
    closeCreateProductModal: '[UI] Close Create Product Modal',

    openUpdateProductModal: '[UI] Open Update Product Modal',
    closeUpdateProductModal: '[UI] Close Update Product Modal',

    selectProductUpdate: '[UI] Open Create Product Modal',

    openCreateImageModal: '[UI] Open Create Image Modal',
    closeCreateImageModal: '[UI] Close Create Image Modal',

}

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
