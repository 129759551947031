import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function AuthGuard({ children, ...props }) {
    const { isLoggedIn, user } = useSelector((state) => state.auth);

    if (!isLoggedIn) {
        //return <Navigate to="/login" />;
        return <div className='fixed  z-50 left-0 top-0 bottom-0 right-0 flex flex-col justify-center items-center  bg-white bg-opacity-25'>
            <div className='flex flex-col justify-center items-center max-w-xs'>
                <img className='w-40 h-40' src='https://d18i3wxus81zw3.cloudfront.net/fit-in/512x512/valle-norte.png' />
                <div className='font-bold text-lg text-gray-800'>
                    ERROR AL INICIAR
                </div>
                <div className='text-gray-500 text-center mt-2'>
                    El TOKEN caduco o hubo un problema con el servidor, comuníquese con nuestra área de atención al cliente.
                </div>

                <a href='https://api.whatsapp.com/send?phone=51986132789' target="__blank" className='flex items-center justify-center mt-4 w-full text-center text-white bg-gradient-to-b from-green-600 to-bg-red-colgate bg-red-colgate rounded-md py-1'>
                    <span className='mr-2'>
                        <svg className='w-5 h-5' fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>WhatsApp icon</title><path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" /></svg>
                    </span>
                    ATENCIÓN AL CLIENTE
                </a>

            </div>
        </div>
    }

    if (((props.profiels && !props.profiels?.includes(user.profile)) || (props.nprofiels && props.nprofiels.includes(user.profile)))) {
        return <Navigate to="/" />;
    }

    return children;

}

AuthGuard.propTypes = {
    children: PropTypes.any
};

export default AuthGuard;
