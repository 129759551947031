import { BrowserRouter } from 'react-router-dom';
import ScrollReset from './components/ScrollReset';
import Routes from './Routes';
import Auth from './components/Auth';

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Auth>
        <ScrollReset />
        <Routes />
      </Auth>
    </BrowserRouter >
  );
}

export default App;
