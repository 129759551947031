import { types } from '../types/types';

const initialState = {
    loading: false,
    msgError: null,
    createProductModal: {
        loading: false,
        open: false
    },
    createImageModal: {
        loading: false,
        open: false
    },
    updateProductModal: {
        loading: false,
        open: false,
        productIdSelected: null
    },
}


export const uiReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.uiSetError:
            return {
                ...state,
                msgError: action.payload
            }

        case types.uiRemoveError:
            return {
                ...state,
                msgError: null
            }

        case types.uiStartLoading:
            return {
                ...state,
                loading: true
            }

        case types.uiFinishLoading:
            return {
                ...state,
                loading: false
            }

        case types.openCreateImageModal:
            return {
                ...state,
                createImageModal: {
                    ...state.createImageModal,
                    open: true,
                }
            }

        case types.closeCreateImageModal:
            return {
                ...state,
                createImageModal: {
                    ...state.createImageModal,
                    open: false,
                }
            }

        case types.openCreateProductModal:
            return {
                ...state,
                createProductModal: {
                    ...state.createProductModal,
                    open: true,
                }
            }

        case types.closeCreateProductModal:
            return {
                ...state,
                createProductModal: {
                    ...state.createProductModal,
                    open: false,
                }
            }

        case types.openUpdateProductModal:
            return {
                ...state,
                updateProductModal: {
                    ...state.updateProductModal,
                    open: true,
                }
            }

        case types.closeUpdateProductModal:
            return {
                ...state,
                updateProductModal: {
                    ...state.updateProductModal,
                    open: false,
                }
            }

        default:
            return state;
    }

}
